@import url("https://fonts.cdnfonts.com/css/otomanopee-one");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&display=swap");
@font-face {
  font-family: 'Dot Matrix';
  src: url('./fonts/DOTMATRI.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aptos';
  src: url('./fonts/aptos-extrabold-italic.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* body {
  margin: 0;
  font-family: "Poppins, sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  background-color: white;
} */

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  font-family: "Poppins, sans-serif";
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  /* display: block; */
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* 
  This CSS rule targets the `.ant-btn-default` class.
  
*/
:where(.css-dev-only-do-not-override-m4timi).ant-btn-default {
  box-shadow: none !important;
}
/*
  8. Create a root stacking context
*/
/* #root {
  isolation: isolate;
} */

/* This styling is specifically for react-slick library */
.button__bar {
  display: flex !important;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  width: 70px;
  text-align: center;
  position: relative;
  top: -35px;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: #50505033;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 6px;
  width: 4px;
  border-radius: 50%;
  padding: 4px;
}

.button__bar li.slick-active button {
  background-color: white;
  width: 31px;
  /* height: 7px; */
  border-radius: 7.5px;
  padding: 3px;
}

.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}

.custom-time-picker-popup {
  z-index: 1500 !important;
}

/* This styling is specifically for react-slick library */
